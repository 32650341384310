@import "src/assets/styles/globalStyles";

.widget-filters-box {
  @include spaceBetween;
  border-radius: 8px;
  border: 1px solid #E7F0FC;
  background: #FFF;
  padding: 16px;



  .MuiOutlinedInput-root {
    width: 250px !important;
  }
}


.widget-card-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  background: #FFF;
  min-width: 250px;
  width: 250px;
  height: 150px;
  border-radius: 8px;
  border: 1px solid #F2F4F8;

  .active-widget-card {
    border: 2px solid #0C66E4;
  }

  .widget-card-info {
    border-radius: 8px 8px 0 0;
    padding: 4px 16px 16px 16px;
    border-bottom: 1px solid #F2F4F7;
    background: #FFF;
    height: 87px;
  }

  .delete-widget {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      @include resetBtn;
      width: 18px;
      height: 18px;
    }
  }

  .widget-card-icon-name-box {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    p {
      color: #161E54;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
    }

    .widget-card-icon {
      margin-top: 4px;
      min-width: 24px;
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }
  }

  .widget-card-rate-box {
    @include spaceBetween;
    padding: 10px 16px;

    .rate {
      color: #161E54;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }

    .plus-percentage {
      display: flex;
      gap: 8px;
      align-items: center;
      border-radius: 4px;
      padding: 0px 4px;
      background: #D1F1D9;

      p {
        color: #2A8E6D;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 200% */
      }
    }

    .minuse-percentage {
      display: flex;
      gap: 8px;
      align-items: center;
      border-radius: 4px;
      padding: 0px 4px;
      background: #FCEBE7;

      p {
        color: #DE350B;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 200% */
      }
    }
  }

}


.add-widget-modal {
  font-family: "Open Sans", sans-serif;

  & .MuiDrawer-paper {
    height: 100vh;
    width: 470px;
    right: 0;
    bottom: 0;
    top: unset;
    background: #FFF;
    box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
    padding: 32px 24px;
  }

  .add-widget-header {
    @include spaceBetween;
    margin-bottom: 32px;

    p {
      color: #172B4D;
      font-size: 24px;
      font-weight: 700;
    }
  }

  .add-widget-body {
    height: 80vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
}


.widget-list-card-box {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid #EBF2FD;
  background: #FFF;

  .widget-list-card-info {
    @include spaceBetween;
    gap: 8px;
  }

  .widget-list-card-icon-name-box {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      min-width: 32px;
      width: 32px;
      height: 32px;
    }

    p {
      color: #161E54;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .widget-list-card-desc {
    color: rgba(0, 0, 0, 0.80);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.154px;
  }

  .add-widget-btn {
    @include flexCenter;
    gap: 8px;
    border: none;
    border-radius: 4px;
    background: #E7F0FC;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 86px;
    height: 32px;
    color: #0C66E4;
    font-weight: 600;

    div {
      width: 19px;
      height: 19px;
    }
  }

  .added-widget-btn {
    @include flexCenter;
    gap: 8px;
    border: none;
    height: 32px;
    width: 103px;
    min-width: 103px;
    border-radius: 4px;
    background: #0C66E4;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #FFF;
    font-weight: 600;
  }
}

.chard-wrapper {
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #E7F0FC;
  background: #FFF;
}

.bar-chart-box {
  padding: 24px 32px;

  .chart-title-action-box {
    @include spaceBetween;
    margin-bottom: 20px;

    .chart-title {
      color: #172B4D;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  .previous-current-box {
    @include flexCenter;
    gap: 12px;

    p {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    div {
      min-width: 12px;
      height: 12px;
      border-radius: 2px;
    }
  }

  .MuiChartsLegend-root {
    display: none;
  }

  .MuiChartsAxis-tick {
    stroke: #F0F1F3 !important;
    font-size: 14px;
    color: #858D9D;
    font-weight: 500;
    font-family: Open Sans, sans-serif;
  }

  .MuiChartsAxis-line {
    stroke: #F0F1F3 !important;
  }

  .MuiChartsGrid-line {
    stroke: #F0F1F3 !important;
  }

  .MuiChartsAxis-tickLabel {
    fill: #858D9D !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }


}

.chart-types-box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.select-chard-selector{
  width: 160px;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
    cursor: pointer;
    //color: rgba(75, 92, 104, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
    background: #F8FAFB;
    cursor: pointer;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding-right: 0;
  }
   & img{
    margin-top: -10px;
  }
   .MuiOutlinedInput-notchedOutline {
     border: none!important;
   }
}

.chart-type-buttons-box {
  width: auto;
  border-radius: 4px;
  border: 1px solid #F2F4F8;
  background: #FCFCFD;
  display: flex;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 0;
    border: none;
  }

  button:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  button:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  .active-chart {
    background: #0C66E4;


    img {
      filter: brightness(0) saturate(100%) invert(93%) sepia(88%) saturate(966%) hue-rotate(204deg) brightness(130%) contrast(94%);
    }
  }

  .passive-chart {
    background: #FCFCFD;

    img {
      filter: brightness(0) saturate(100%) invert(27%) sepia(55%) saturate(3648%) hue-rotate(209deg) brightness(92%) contrast(95%);
    }
  }
}

.pie-chart-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .MuiChartsLegend-root {
    display: none !important;
  }
}

.widget-cards-wrapper {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 170px;
  gap: 16px;
  width: calc(100vw - 250px);
  position: relative;
  z-index: 10;
  @media (min-width: 1919px) {
    width: calc(100vw - 300px);
  }

  ::-webkit-scrollbar {
    display: block !important;
  }
}

.widget-card-back-drop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2000;
}

.dragged-card-back{
  background: transparent;
  min-width: 250px;
  width: 250px;
  height: 150px;
  border-radius: 8px;
  border: 2px dashed #D0D5DD;
}

.widget-date-selector-box {
  display: flex;
  flex-direction: column;

  .rdrInputRanges {
    display: none;
  }

  .MuiBox-root {
    margin-top: 16px;
    margin-left: -16px;
    width: 300px;
    border: 1px solid #F4F4F4;
    padding: 8px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
  }

  .clear-bnt {
    background: transparent;
    border: none;
    outline: none;
    margin-top: -32px;
    width: 85px;
    color: #0C66E4;
    font-size: 16px;
  }
}

.line-chat{
  margin-top: 59px;
}

.pie-chard-legend-wrapper{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  gap: 8px;
  height: 300px;
  width: fit-content;
  @media (min-width: 1919px) {
    flex-wrap: wrap;
  }

  .pie-chart-legend-box{
    display: flex;
    align-items: flex-start;
    width: 200px;
    gap: 8px;

    & div{
        width: 20px;
        min-width: 20px;
        height: 20px;
        border-radius: 2px;
    }

    & .text-style{
      width: 100%;
    }

    & p{
      font-size: 16px;
      line-height: 19px;
    }


  }
}