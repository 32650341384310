@import "src/assets/styles/globalStyles";

// checkbox-input-style
.checkbox-label {
  display: inline-flex;
  align-items: center;
  transition: all 0.1s linear;
  cursor: pointer;

  & input {
    display: none;
  }

  & input:checked + .input-checkbox {
    background-image: url("../../assets/images/landingPage/common/checkMarkFill.svg");
  }

  .input-checkbox {
    transition: background-image 0.1s linear;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-image: url("../../assets/images/landingPage/common/checkMarkEmpty.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-right: 8px;
  }

  .input-label-text {
    @include font-style(16px, 500, $textSecondary, 18px);
  }
}

// select-input-style
.select-input {
  .label-text {
    @include font-style(16px, 500, $textSecondary, 24px);
    display: flex;
    margin-bottom: 6px;
    @media (max-width: $mobile) {
      margin-bottom: 4px;
    }

    &::before {
      content: "*";
      color: $themePrimary;
      margin-right: 4px;
    }
  }

  .html-select {
    border: none;
    outline: none;
    width: 321px;
    height: 48px;
    padding: 0 16px;
    margin-bottom: 32px;
    border-radius: 10px;
    background-color: $backgroundPrimary;
    cursor: pointer;
    @media (max-width: $desktop) {
      width: 308px;
    }
    @media (max-width: $mobile) {
      width: 295px;
      margin-bottom: 16px;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../assets/images/landingPage/common/dropdownArrow.svg");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: top 50% right 16px;
  }
}

.beck-drop {
  height: 100vh;
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
}

.date-selector-label {
  font-size: 14px;
  font-weight: 600;
  color: #347AF0;
  margin-bottom: 5px;
}

.modal-date-selector-label {
  font-weight: 400;
  font-size: 12px;
  color: #347AF0;
  margin-bottom: -7px;
  background: white;
  z-index: 10;
  position: relative;
  width: fit-content;
  padding: 0 4px;
  margin-top: -5px;
  margin-left: 14px;

}

.date-range-selector {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid rgba(163, 178, 189, 0.50);
  background: #FFF;
  height: 36px;
  margin-top: 8px;

  p {
    color: rgba(34, 34, 34, 0.80);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
  }

  svg {
    filter: invert(0.5);
  }
}

.modal-date-range-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid #0C66E4;
  background: #FFF;
  width: 100%;
  height: 48px;

  p {
    color: rgba(75, 92, 104, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    white-space: nowrap;
  }

  svg {
    filter: invert(0.5);
  }

  input{
    font-size: 16px;
    width: 100% !important;
  }
}

.modal-date-range-selector-dos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid #0C66E4;
  background: #FFF;
  width: 100%;
  height: 48px;

  p {
    color: rgba(75, 92, 104, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    white-space: nowrap;
  }

  svg {
    filter: invert(0.5);
  }

  input{
    font-size: 16px;
    width: 100%!important;
  }
}

.date-range-inputs{
  display: flex;
  align-items: center;

  p{
    color: rgb(81, 86, 109);
  }
  input{
    border: none;
    background: transparent;
    outline: none;
    width: 81px;
    color: rgb(81, 86, 109);
  }
}

.custom-date-selector-box {
  display: flex;
  flex-direction: column;

  .MuiBox-root {
    margin-top: 16px;
    margin-left: -16px;
    width: 300px;
    border: 1px solid #F4F4F4;
    padding: 8px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .clear-bnt {
    background: transparent;
    border: none;
    outline: none;
    margin-top: -32px;
    width: 85px;
    color: #0C66E4;
    font-size: 16px;

  }
}

.modal-custom-date-selector-box {
  display: flex;
  flex-direction: column;

  .rdrCalendarWrapper{
    box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
    border-radius: 8px;
    background: white;
    padding-bottom: 20px;
  }

  .rdrCalendarWrapper {
    margin-top: 0;
    //margin-top: 16px;
    //margin-left: -16px;
    //width: 300px;
    //border: 1px solid #F4F4F4;
    //padding: 8px;
    //border-radius: 4px;
    //background: #FFF;
    //box-shadow: 0 0 6px 0 rgba(138, 138, 138, 0.24);
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .clear-bnt {
    background: transparent;
    border: none;
    outline: none;
    margin-top: -32px;
    width: 85px;
    color: #0C66E4;
    font-size: 16px;

  }

}

.remove-calendar-day{
  background: transparent;
  border: none;
  padding: 0;

  & img{
    fill: black;
    width: 21px;
    height: 21px;
    margin-top: 5px;
  }
}


.input-main-wrapper {
  width: 100%;
  position: relative;


  .MuiInputAdornment-root {
    width: 24px;
    cursor: pointer;
    height: fit-content;
    display: flex;
    justify-content: center;


    &.error-svg {
      svg {
        path {
          stroke: $errorColorInput;
        }
      }
    }

    &.error-svg-password {
      svg {

        path {
          stroke: $errorColorInput;
        }
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-root {
      display: flex;
      align-items: center;
    }

    input {
      padding: 12px 16px;
    }


    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $errorColorInput;
      }
    }
  }

  &.input-custom-wrapper {
    .MuiInputBase-root {
      &:hover {
        .MuiInputAdornment-positionStart {
          svg {

            path {
              stroke: #555555;
            }
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #555;
        }
      }
    }

    input {
      padding: 12px 16px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #9D9D9D;
    }

    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $errorColor;
      }
    }

    .Mui-focused {
      .MuiInputAdornment-positionStart {
        svg {

          path {
            stroke: #555555;
            stroke-width: 1.5;
          }
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: 2px solid #262626;
      }
    }

  }

  .MuiInputAdornment-root ~ input {
    padding-left: 0;
  }

  //.MuiSvgIcon-root {
  //  fill: #292D32;
  //}

}

.input-main-label-header, .input-main-label-header-required {
  color: $titleColor10;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;


  &.errorLabel {
    color: $errorColorInput;

    .input-main-label-header-required {
      color: $errorColorInput;
    }

  }
}

//.error-message {
//  color: $errorColorInput;
//  font-size: 12px;
//  font-weight: 400;
//  min-height: 18px;
//  margin-left: 15px;
//  margin-top: 2px
//}
//

.address-wrapper {
  position: relative;
  width: 100%;

  .suggestion-box {
    @include alignCenter;
    text-align: start;
  }

  .places-autocomplete-label {
    position: absolute;
    padding: 16px 14px;
    font-size: 16px;
    color: rgba(75, 92, 104, 0.7);
    z-index: 1;
    //transition: all .2s;
  }

  input {
    position: relative;
    z-index: 2;
    background: transparent;
  }

  .places-autocomplete-label-active {
    //transition: all .2s;
    font-size: 12px;
    color: $themeBlue;
    position: absolute;
    background: white;
    margin-top: -4px;
    padding: 0 6px;
    margin-left: 8px;
    z-index: 3;
  }
}


.numeric-format-input {
  width: 100%;
  height: 48px;
  padding: 13px 16px;
  border-radius: 4px;
  border: 1px solid #347AF0;
  background: #FFF;
  color: #4B5C68;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;

  &:focus {
    outline: none;
    border: 2px solid $themeBlue;
  }
}

.numeric-format-input-error {
  width: 100%;
  height: 48px;
  padding: 13px 16px;
  border-radius: 4px;
  border: 1px solid $errorColorInput;
  background: #FFF;
  color: #4B5C68;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;

  &:focus {
    outline: none;
    border: 2px solid $errorColorInput;
  }

  &::placeholder {
    color: $errorColorInput;
  }
}


.custom-numeric-format-box {
  position: relative;

  .numeric-autocomplete-label {
    position: absolute;
    padding: 16px 14px;
    font-size: 16px;
    color: rgba(75, 92, 104, 0.7);
    z-index: 1;
    //transition: all .2s;
  }

  input {
    position: relative;
    z-index: 2;
    background: transparent;
  }

  .numeric-autocomplete-label-active {
    //transition: all .2s;
    font-size: 12px;
    color: $themeBlue;
    position: absolute;
    background: white;
    margin-top: -4px;
    padding: 0 6px;
    margin-left: 8px;
    z-index: 3;
  }

  .numeric-autocomplete-label-error {
    color: $errorColorInput;
  }
}

.numeric-format-input::placeholder {
  background: #4B5C68;

}

.date-input-box {
  position: relative;
  width: 100%;

  .react-datepicker__calendar-icon {
    display: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .date-input-title {
    color: #51566D;
    font-size: 12px;
    position: absolute;
    z-index: 3;
    margin-top: -5px;
    background: white;
    padding: 0 4px;
    margin-left: 14px;
  }

  .react-datepicker__input-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .react-datepicker__calendar-icon {
    margin-right: 12px;
    filter: invert(46%) sepia(87%) saturate(2187%) hue-rotate(200deg) brightness(101%) contrast(102%);
    z-index: 1;
  }

  .date-picker-box {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #0C66E4;
    background: transparent;
    padding: 0 16px;
    font-size: 16px;
    color: #4B5C68;;
    position: relative;
    z-index: 2;
    outline: none;
    text-align: start;
  }

  .date-picker-search-box {
    width: 200px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #0C66E4;
    background: transparent;
    padding: 0 16px;
    font-size: 16px;
    color: #51566D;
    position: relative;
    z-index: 2;
    outline: none;
    text-align: start;
  }

  .date-picker-filter-box {
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #D0D2D7;
    background: #FFF;
    height: 36px;
    width: 155px;
    font-size: 14px;
    @media (min-width: 1919px) {
      width: 220px;
    }
  }

  //
  //  width: 200px;
  //  height: 36px;
  //  border-radius: 4px;
  //  border: 1px solid #0C66E4;
  //  background: transparent;
  //  padding: 0 16px;
  //  font-size: 16px;
  //  color: #51566D;
  //  position: relative;
  //  z-index: 2;
  //  outline: none;
  //  text-align: start;
  //}

  .date-picker-error {
    border: 1px solid #f44336;
  }

  .date-picker-disabled {
    border: 1px solid rgba(0, 0, 0, 0.26);
  }

  .react-datepicker__close-icon {
    margin-right: 40px;
  }

  .react-datepicker__close-icon::after {
    z-index: 4;
    position: relative;
    background: transparent;
    color: #51566D;
    font-size: 24px;
  }

  .react-datepicker-popper {
    z-index: 10;
  }
}

.time-input-box {
  position: relative;
  width: 100%;

  .time-input-title {
    color: #51566D;
    font-size: 12px;
    position: absolute;
    z-index: 3;
    margin-top: -5px;
    background: white;
    padding: 0 4px;
    margin-left: 14px;
  }

  .time-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #0C66E4;
    background: transparent;
    padding: 0 16px;
    font-size: 16px;
    color: #51566D;
    position: relative;
    z-index: 2;
    outline: none;
  }

  .time-input-error {
    border: 1px solid #f44336;
  }
}

.custom-select-time-box {
  box-shadow: 0px 0px 6px #0000001a;
  margin-top: 4px;
  width: 180px;
  max-height: 200px;
  overflow: hidden;
  background: white;
  border-radius: 8px;
  padding: 8px 0;

  .hour-minute-box {
    display: flex;
    align-items: flex-start;

    div {
      text-align: center;
      width: 60px;
      background: white;
      height: 200px;
      overflow: auto;
      display: flex;
      flex-direction: column;

      button {
        background: transparent;
        border: none;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 0;
        cursor: pointer;

        &:hover {
          background: rgba(52, 122, 240, 0.50);
        }
      }
    }
  }
}

.selector-wrapper{
  display: flex;
  align-items: center;

  p{
    font-size: 14px;
  }
}

.add-new-address{
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #0C66E4;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}


.refresh-btn-wrapper {
  bottom: -20px;
  position: absolute;
  width: 100%;
  margin-left: -16px;
  padding: 8px 14px 10px 14px;
  background: white;
  border-top: 1px solid rgba(163, 178, 189, 0.30);
  border-radius: 0 0 4px 4px;

  .refresh-btn {
    @include resetBtn;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #0C66E4;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    .refresh-btn-image-box{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }
}